<template>
  <div class="main-panel">
    <div class="title">
      <span>待整改泛团</span>
      <div class="d-inline-block ml-5 search">
        <el-input type="text" clearable v-model="search" placeholder="请输入ID或泛团名" style="width: 350px;"></el-input>
        <el-button type="primary" @click="searchTeam" style="margin-left: -3px;">搜索</el-button>
      </div>
    </div>
    <myTable :list="list" :name='name'>
      <template #before='scoped'>
        <div class="expand-main">
          <div class="row">
            <span>联系电话：{{scoped.row.phone}}</span>
            <span>联系邮箱：{{scoped.row.email}}</span>
          </div>
          <div class="row warp">
            <span>泛团简介：</span>
            <span>{{scoped.row.level}}</span>
          </div>
          <div class="row last-row">
            泛团备注：<el-input :disabled="true" v-model="scoped.row.comment" size="mini" ></el-input>
          </div>
        </div>
			</template>
      <template #after='scoped'>
        <div class="btns-main">
          <span @click="online(scoped.row.teamid)">上线</span>
        </div>
			</template>
    </myTable>
    <el-pagination v-if="list.length > 0" layout="prev, pager, next" :page-size="10"  :total="totalRow" @current-change="changePage">
  </el-pagination>
  </div>
</template>

<script>
import myTable from './my-table.vue'
import { getTeamsInfo, sendChstatus } from '@/api/team-info'
export default {
  name: 'abarbeitung',
  components: {
    myTable
  },
  data () {
    return {
      search: '',
      name: 'abarbeitung',
      page: 1,
      totalRow: 0,
      list: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    async getList () {
      const params = {
        search: this.search,
        page: this.page,
        pageSize: 10,
        status: 3
      }
      const res = await getTeamsInfo(params)
      if (res.code === 0) {
        this.totalRow = res.data.pager.totalRow
        this.list = res.data.teams || []
      } else {
        this.$notify({
          title: '失败',
          type: 'error',
          message: res.message,
          duration: 2000
        })
      }
    },
    searchTeam () {
      this.page = 1
      this.getList()
    },
    changePage (p) {
      this.page = p
      this.getList()
    },
    online (id) {
      const data = {
        teamid: id,
        status: 0
      }
      sendChstatus(data).then(res => {
        if (res.code === 0) {
          if (this.list.length === 1) {
            this.page = this.page - 1
          }
          this.getList()
          this.$notify({
            title: '成功',
            message: res.message,
            type: 'success',
            duration: 1000
          })
        } else {
          this.$notify({
            title: '失败',
            type: 'error',
            message: res.message,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main-panel{
  .title{
    font-size: 20px;
    line-height: 20px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 36px;
  }
  .el-pagination{
    margin-top: 190px;
  }
  .expand-main{
    overflow: hidden;
    padding: 0 1px;
    .last-row{
      padding-right: 50px;
      /deep/.el-input__inner{
        border-radius: 6px !important;
        border: 1px solid #BBBBBB;
      }
    }
    .row{
      display: flex;
      margin: 0;
      margin-bottom: 10px;
      /deep/.el-input{
        flex: 1;
        .el-input__inner{
          border-radius: 0px
        }
        .el-input__inner:focus{
          border: 1px solid #707070;
        }
      }
      span{
        flex: 1;
      }
    }
    .warp{
      width: 100%;
      display: flex;
      span:first-child{
        flex: 1;
        max-width: 70px;
        min-width: 70px;
      }
      span:st-child{
        flex: 9;
        width: 100%;
      }
    }
  }
  .btns-main{
    span{
      cursor: pointer;
      display: inline-block;
      width: 56px;
      height: 34px;
      border-radius: 8px;
      background-color: #3775F6;
      line-height: 34px;
      text-align: center;
      color: #FFFFFF;
    }
  }
}
</style>
