<template>
  <div>
    <el-table :data="list"  style="width: 100%" :header-cell-style="{'text-align':'center','color': '#333333'}" :cell-style="{'text-align':'center','color': '#555555'}" empty-text>
      <el-table-column type="expand">
        <template v-if="$scopedSlots['before']" slot-scope="scope">
          <slot name='before' :row = scope.row></slot>
        </template>
      </el-table-column>
      <el-table-column  label="ID"  prop="teamid"></el-table-column>
      <el-table-column  label="泛团名称" prop="teamname"></el-table-column>
      <el-table-column  label="泛团性质" prop="teamtype">
        <template slot-scope="scope">
          <span>{{scope.row.teamtype === 0? '企业': (scope.row.teamtype === 1? '个人' : '-')}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="主要业务" width="180px">
        <template slot-scope="scope">
          <span class="tag" v-for="it in businessArr (scope.row.business)" :key="it">{{it}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="成立日期" prop="created">
        <template slot-scope="scope">
          <span>{{formatTime(scope.row.created)}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="泛团成员" prop="teamernum"></el-table-column>
      <el-table-column  label="参与项目" prop="tmprojectnum">
        <template slot-scope="scope">
          <span class="tmprojectnum" @click="showDone(scope.row.teamid)">{{scope.row.tmprojectnum}}</span>
        </template>
      </el-table-column>
      <el-table-column  label="审核状态">
          <span class="names" :class="name">{{name === 'established'? '通过' : (name === 'refused'? '上线': '整改')}}</span>
      </el-table-column>
      <!-- <el-table-column  label="操作">
        <template slot-scope="scope">
          <span v-if="false">{{scope.row.business}}</span>
          <div v-if="name === 'established' ">123</div>
        </template>
      </el-table-column> -->
      <el-table-column  label="操作" min-width="120px" v-if="name !== 'refused'">
        <template v-if="$scopedSlots['after']" slot-scope="scope">
          <slot name='after' :row = scope.row></slot>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="参与项目"  top="25vh" :visible.sync="doneFlag" :before-close="handleClose">
      <div class="done-table">
        <div class="row">
          <div class="left">项目</div>
          <div class="right">项目名称</div>
        </div>
        <div class="row" v-for="it in doneList" :key="it">
          <div class="left">{{it.id}}</div>
          <div class="right">{{it.name}}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { projectDone } from '@/api/team-info'
export default {
  name: 'my-table',
  props: {
    list: Array,
    name: String
  },
  data () {
    return {
      option: [
        {
          label: '图片',
          value: 1
        },
        {
          label: '文本',
          value: 2
        },
        {
          label: '视频',
          value: 4
        },
        {
          label: '语音',
          value: 8
        },
        {
          label: '采集',
          value: 16
        },
        {
          label: '3D点云标注',
          value: 32
        }
      ],
      doneFlag: false,
      doneList: []
    }
  },
  mounted () {
    // console.log(this.list)
  },
  methods: {
    businessArr (business) {
      const arr = []
      this.option.map(
        val => {
          if (val.value & business) {
            arr.push(val.label)
          }
        }
      )
      return arr
    },
    formatTime (currentTime) {
      const time = new Date(currentTime * 1000)
      const y = time.getFullYear()
      const m = (time.getMonth() + 1) < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
      const d = time.getDate() < 10 ? '0' + time.getDate() : time.getMonth() + time.getDate()
      const dateTime = y + '.' + m + '.' + d
      return dateTime
    },
    showDone (id) {
      projectDone(id).then(res => {
        console.log(res)
        if (res.code === 0) {
          this.doneFlag = true
          this.doneList = res.data.projects || [{}]
        } else {
          this.$notify({
            title: '失败',
            type: 'error',
            message: res.message,
            duration: 2000
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-table{
  .tag{
    display: inline-block;
    line-height: 19px;
    padding: 3px 5px;
    color: #666666;
    border: 1px solid #666666;
    font-size: 13px;
    margin-right: 7px;
    margin-bottom: 4px;
  }
  /deep/.tmprojectnum{
    cursor: pointer;
    color: #3775F6;
  }
  /deep/.names{
    display: inline-block;
    padding: 0 14px;
    height: 34px;
    line-height: 34px;
    color: #FFFFFF;
    border-radius: 8px;
  }
  /deep/.established{
    background-color: #3775F6;
  }
  /deep/.refused{
    background-color: #F56C6C;
  }
  /deep/.abarbeitung{
    background-color: #F69737;
  }
}
</style>
<style lang="less">
.el-dialog{
  width: 340px;
  height: 196px;
  border-radius: 15px;
  .el-dialog__body{
    padding: 20px 5px;
    height: 160px;
  }
  button:focus {
    outline: 5px solid #ffffff;
  }
  .done-table{
    overflow: hidden;
    width: 278px;
    margin: 0 auto;
    border-left: 1px solid #707070;
    border-top: 1px solid #707070;
    .row{
      font-size: 14px;
      color: #333333;
      margin: 0 auto;
      div{
        min-height: 32px;
        border-right: 1px solid #707070;
        border-bottom: 1px solid #707070;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .left{
        width: 99px;
      }
      .right{
        width: 178px;
      }
    }
  }
}
</style>
